import MyRentalsConfig from "@/services/MyRentalsConfig";
import esLocal from "@/config/es.local.json";
import esProd from "@/config/es.prod.json";
import itProd from "@/config/it.prod.json";
import { Country, Environment } from "@/enums/Config";

describe("MyRentalsConfig", () => {
  beforeEach(() => {
    jest.restoreAllMocks();
  });
  it("should return ES Local config if needed", () => {
    spyOn(MyRentalsConfig, "env").and.returnValue({
      ENVIRONMENT: Environment.LOCAL,
    });
    expect(MyRentalsConfig.country()).toBe(Country.ES);
    expect(MyRentalsConfig.config()).toEqual(esLocal);
  });
  it("should return ES Prod config by default", () => {
    spyOn(MyRentalsConfig, "env").and.returnValue({
      ENVIRONMENT: Environment.PROD,
    });
    expect(MyRentalsConfig.country()).toBe(Country.ES);
    expect(MyRentalsConfig.config()).toEqual(esProd);
  });
  it("should return IT Prod config by default", () => {
    spyOn(MyRentalsConfig, "country").and.returnValue(Country.IT);
    spyOn(MyRentalsConfig, "env").and.returnValue({
      ENVIRONMENT: Environment.PROD,
    });
    expect(MyRentalsConfig.country()).toBe(Country.IT);
    expect(MyRentalsConfig.config()).toEqual(itProd);
  });
  it("should return ES Prod config by default if environment is invalid", () => {
    spyOn(MyRentalsConfig, "env").and.returnValue({ ENVIRONMENT: "invalid" });
    expect(MyRentalsConfig.country()).toBe(Country.ES);
    expect(MyRentalsConfig.config()).toEqual(esProd);
  });
});
